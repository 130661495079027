import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { Row, Col, Radio, Card, DatePicker, Typography } from 'antd';
//import moment from 'moment';
import 'moment/locale/pt-br';

import '../../../css/global.css';
import Barra from '../../../components/graficos/Barra';

import coresGraficos from '../../../Config/cores';

//import { Link } from 'react-router-dom';
import api from '../../../services/api';

export default class Certidao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicioFormatada: '',
      dataFimFormatada: '',
      //checked: true,
      recepcao: {
        chatData: {
          labels: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta'],
          datasets: [
            {
              label: 'Certidão',
              data: [0, 0, 0, 0, 0],
              borderWidth: 1,
              //lineTension:0,
              backgroundColor: [
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
              ],
              //fill:false,
              borderColor: [
                coresGraficos.linha[0],
                coresGraficos.linha[0],
                coresGraficos.linha[0],
                coresGraficos.linha[0],
                coresGraficos.linha[0],
              ]
            },
            {
              label: 'Exame cálculo',
              data: [0, 0, 0, 0, 0],
              borderWidth: 1,
              backgroundColor: [
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
              ],
              //fill:false,
              borderColor: [
                coresGraficos.linha[1],
                coresGraficos.linha[1],
                coresGraficos.linha[1],
                coresGraficos.linha[1],
                coresGraficos.linha[1],

              ]
            },
            {
              label: 'Protocolo',
              data: [0, 0, 0, 0, 0],
              borderWidth: 1,
              backgroundColor: [
                coresGraficos.preenchimento[2],
                coresGraficos.preenchimento[2],
                coresGraficos.preenchimento[2],
                coresGraficos.preenchimento[2],
                coresGraficos.preenchimento[2],
              ],
              //fill:false,
              borderColor: [
                coresGraficos.linha[2],
                coresGraficos.linha[2],
                coresGraficos.linha[2],
                coresGraficos.linha[2],
                coresGraficos.linha[2],
              ]

            }
          ],

        },
        options: {
          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }

      },
      atendimento: {
        chatData: {
          labels: ['Atendidos', 'cancelados'],
          position: "bottom",
          datasets: [
            {
              label: 'Atendimentos',
              borderWidth: 1,
              data: [0, 0],
              backgroundColor: [
                coresGraficos.preenchimento[2],
                coresGraficos.preenchimento[0],
              ],
              borderColor: [
                coresGraficos.linha[2],
                coresGraficos.linha[0],
              ]
            }
          ]
        },
        options: {
          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      },
      certidao: {
        chatData: {
          labels: ['Entrada', 'Andamento', 'Pronta'],
          datasets: [
            {
              label: 'Certidões',
              borderWidth: 1,
              data: [0, 0, 0],
              backgroundColor: [
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[2],
              ],
              borderColor: [
                coresGraficos.linha[0],
                coresGraficos.linha[1],
                coresGraficos.linha[2],
              ]
            }
          ]
        },
        options: {
          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      },
      protocolo: {
        chatData: {
          labels: ['Entrada', 'Andamento', 'Pronto'],
          datasets: [
            {
              label: 'Certidões',
              data: [0, 0, 0],
              borderWidth: 1,
              backgroundColor: [
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[2],
              ],
              borderColor: [
                coresGraficos.linha[0],
                coresGraficos.linha[1],
                coresGraficos.linha[2],
              ]
            }
          ]
        },
        options: {
          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      },
      exameCalculo: {
        chatData: {
          labels: ['Entrada', 'Andamento', 'Pronto'],
          datasets: [
            {
              label: 'exame cálculo',
              data: [0, 0, 0],
              borderWidth: 1,
              backgroundColor: [
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[2],
              ],
              borderColor: [
                coresGraficos.linha[0],
                coresGraficos.linha[1],
                coresGraficos.linha[2],
              ]
            }
          ]
        },
        options: {
          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      }
    }
  }

  formataData(state, data) {
    const dd = data.split("-");
    const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
    //const dataFormatada = format(data, 'dd/MM/YYY');
    this.setState({ [state]: dataPronta });
    //console.log(dd);
  }

  formataDataPeriodo(data) {
    const dd = data.split("/");
    const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
    return dataPronta;
  }

  formataDataFiltro() {
    const dataHoje = new Date();
    const data = format(dataHoje, 'YYY-MM-dd');
    return data;
  }

  async componentDidMount() {
    this.filtraDia();
  }

  filtraDia = e => {
    const dataFim = this.formataDataFiltro();
    this.enviaFiltragem(dataFim, dataFim);
    this.formataData('dataInicioFormatada', dataFim);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraSemana = async e => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -6);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
    console.log(dataInicio);
  }

  filtraMes = async mes => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -30);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraPeriodo = (value, dateString) => {
    const dataHoje = new Date();
    const data = format(dataHoje, 'dd/MM/YYY');
    const inicio = dateString[0] ? dateString[0] : data;
    const fim = dateString[1] ? dateString[1] : data;
    const dataInicio = this.formataDataPeriodo(inicio);
    const dataFim = this.formataDataPeriodo(fim);

    console.log("onchange", inicio, fim);
    
    this.enviaFiltragem(dataInicio, dataFim);
    this.setState({ dataInicioFormatada: inicio });
    this.setState({ dataFimFormatada: fim });
  }

  somaTotal = (dados) => {
    //let teste = [2,3,4,5];  
    const total = dados.reduce((dado, valor) => {
      return dado + valor;
    });
    return total;
  }

  enviaFiltragem = async (dataInicio, dataFim) => {
    // const atendimentos = await api.get(`/atendimentos/mediaAtendimentos/${dataInicio}/${dataFim}`);
    // const atendimento = atendimentos.data;

    // const atendimentoFinal = atendimentos.data[0].atendimento === null ? 0 : atendimentos.data[0].atendimento;
    // const novoEstadoAtendimento = Object.assign({}, this.state);
    // novoEstadoAtendimento.atendimento.chatData.datasets[0].data = [atendimentoFinal,atendimento[0].espera];
    // this.setState({ novoEstadoAtendimento });
    const ip = localStorage.getItem('MG_IP');
    //atendimentos gerais
    const atendimentosGerais = await api.get(`/atendimentos/atendimentosFinalizados/${dataInicio}/${dataFim}`);
    const atendimentoGeral = atendimentosGerais.data;

    const atendimentoGeralFinal = atendimentosGerais.data[0].atendimento === null ? 0 : atendimentosGerais.data[0].atendidos;
    const novoEstadoAtendimentoGeral = Object.assign({}, this.state);
    novoEstadoAtendimentoGeral.atendimento.chatData.datasets[0].data = [atendimentoGeralFinal, atendimentoGeral[0].cancelados];
    this.setState({ novoEstadoAtendimentoGeral });

    //protocolos
    const protocoloPronto = await api.get(`/protocolo/protocoloPronto/${dataInicio}/${dataFim}/${ip}`);
    const protocolo = protocoloPronto.data;

    //const protocoloFinal = protocoloPronto.data[0].pronto === null ? 0 : protocoloPronto.data[0].pronto;
    const totalProtocolo = parseInt(protocolo[0].andamento) + parseInt(protocolo[0].pronto);
    const novoEstadoProtocolo = Object.assign({}, this.state);
    novoEstadoProtocolo.protocolo.chatData.datasets[0].data = [totalProtocolo, protocolo[0].andamento, protocolo[0].pronto];
    this.setState({ novoEstadoProtocolo });

    //exame calculo
    const examePronto = await api.get(`/exameCalculo/exameCalculoPronto/${dataInicio}/${dataFim}/${ip}`);
    const exame = examePronto.data;

    //const protocoloFinal = protocoloPronto.data[0].pronto === null ? 0 : protocoloPronto.data[0].pronto;
    const totalExameCalculo = parseInt(exame[0].andamento) + parseInt(exame[0].pronto);
    const novoEstadoExameCalculo = Object.assign({}, this.state);
    novoEstadoExameCalculo.exameCalculo.chatData.datasets[0].data = [totalExameCalculo, exame[0].andamento, exame[0].pronto];
    this.setState({ novoEstadoExameCalculo });

    //pedidos de certidão
    const certidoesProntas = await api.get(`/certidao/certidaoPronta/${dataInicio}/${dataFim}/${ip}`);
    const certidao = certidoesProntas.data;

    const pronto = certidoesProntas.data[0].pronto === null ? 0 : certidoesProntas.data[0].pronto;
    const andamento = certidao[0].andamento;

    const totalEntrada = parseInt(andamento) + parseInt(pronto);
    const novoEstado = Object.assign({}, this.state);
    novoEstado.certidao.chatData.datasets[0].data = [totalEntrada, certidao[0].andamento, pronto];
    this.setState({ novoEstado });

    //pedidos de recepção
    const recepcaoProntas = await api.get(`/recepcao/recepcaoEntrada/${dataInicio}/${dataFim}/${ip}`);
    const recepcao = recepcaoProntas.data;
    //console.log(recepcao[2].segunda);       

    const novoEstadoRecepcao = Object.assign({}, this.state);
    const novoEstadoRecepcao1 = Object.assign({}, this.state);
    const novoEstadoRecepcao2 = Object.assign({}, this.state);

    novoEstadoRecepcao.recepcao.chatData.datasets[0].data = [recepcao[0].segunda, recepcao[0].terca, recepcao[0].quarta, recepcao[0].quinta, recepcao[0].sexta];
    novoEstadoRecepcao2.recepcao.chatData.datasets[1].data = [recepcao[1].segunda, recepcao[1].terca, recepcao[1].quarta, recepcao[1].quinta, recepcao[1].sexta];
    novoEstadoRecepcao1.recepcao.chatData.datasets[2].data = [recepcao[2].segunda, recepcao[2].terca, recepcao[2].quarta, recepcao[2].quinta, recepcao[2].sexta];

    this.setState({ novoEstadoRecepcao });
    this.setState({ novoEstadoRecepcao1 });
    this.setState({ novoEstadoRecepcao2 });
    //console.log(this.state.recepcao.chatData.datasets[2].data);
  }

  render() {
    const { RangePicker } = DatePicker;
    const { Title } = Typography;
    return (
      <div>
        <Row gutter={24} type="flex" justify="space-between">
          <Col md={12} lg={12} xl={12}>
            <Title style={{fontWeight:'bold'}} level={2}>Certidão</Title>
          </Col>
          <Col md={12} lg={12} xl={12} >
            <Title style={{textAlign:'right'}} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
          </Col>          
        </Row>  
        <Row gutter={24} type="flex" justify="end">
        <Col md={12} lg={12} xl={12} style={{ marginBottom: '10px',textAlign:'right' }}>
            <RangePicker
              placeholder={['Período...']}
              //onOk={this.onOk}
              onChange={this.filtraPeriodo}
              //defaultValue={[moment(dataHoje, 'DD/MM/YYYY'), moment(dataHoje, 'DD/MM/YYYY')]}
              format={'DD/MM/YYYY'}
            />{'  '}
            <Radio.Group value={14} >
              <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
              <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
              <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>  

        <Row gutter={24}>
          <Col md={24} lg={12} xl={12}>
            <Card size="small" title={<div>CERTIDÃO</div>}>
              <Barra width={80} height={50} periodo={this.state.certidao.chatData} options={this.state.certidao.options} />
            </Card>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col lg={24}>
            <br></br>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={24}>
            <Card size="small" title={<div>CERTIDÃO</div>}>
              <Barra width={100} height={40} periodo={this.state.certidao.chatData} options={this.state.certidao.options} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
