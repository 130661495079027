export default {
    preenchimento:[       
        "rgba(86,79,193,0.4)",
        "rgba(0,208,189,0.4)",
        "rgba(24,144,255,0.4)",
        "rgba(60,179,113,0.4)",
        "rgba(255,183,15,0.4)",
        "rgba(199,21,133,0.4)",
        "rgba(255,55,57,0.4)",
        "rgba(0,208,20,0.4)",
    ],
    linha:[
        "rgba(86,79,193)",
        "rgba(0,208,189)",
        "rgba(24,144,255)",
        "rgba(60,179,113)",
        "rgba(255,183,15)",
        "rgba(199,21,133)",
        "rgba(255,55,57)",
        "rgba(0,208,20)",
    ]
}