import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { Row, Col, Radio, Card, Typography, DatePicker } from 'antd';
import '../../../css/global.css';
import Barra from '../../../components/graficos/Barra';

import coresGraficos from '../../../Config/cores';
//import { Link } from 'react-router-dom';
import api from '../../../services/api';

export default class Atendimento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataInicioFormatada: '',
            dataFimFormatada: '',
            totalSenhasHora: { dados: [0, 0, 0, 0, 0] },
            totalSenhas: { dados: [0, 0, 0, 0, 0] },
            totalTempoMedio: { dados: [0, 0, 0, 0, 0, 0] },
            //checked: true,            
            atendimentoPorHora: {
                chatData: {
                    labels: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00'],
                    datasets: [
                        {
                            label: 'Atendidos',
                            fontSize: 12,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                            ],
                            borderColor: [
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                            ]
                        },
                        //  {
                        //     label: 'Cancelados',
                        //     fontSize: 12,
                        //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        //     borderWidth: 1,
                        //     backgroundColor: [
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //         coresGraficos.preenchimento[1],
                        //     ],
                        //     borderColor: [
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //         coresGraficos.linha[1],
                        //     ]
                        // }
                    ],

                },
                options: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    scales: {
                        yAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                drawOnChartArea: true,
                            }
                        }]
                    },
                }

            },
            atendimentoCliente: {
                chatData: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Atendidos',
                            fontSize: 10,
                            data: [],
                            borderWidth: 1,
                            backgroundColor: [],
                            borderColor: []
                        },
                    ],

                },
                options: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    scales: {
                        yAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                drawOnChartArea: true,
                            }
                        }]
                    },
                }

            },
            atendimentoPorSemana: {
                chatData: {
                    labels: ['segunda', 'terça', 'quarta', 'quinta', 'sexta'],
                    datasets: [
                        {
                            label: 'Atendidos',
                            fontSize: 12,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                            ],
                            borderColor: [
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                            ]
                        },                        
                    ],

                },
                options: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    scales: {
                        yAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                drawOnChartArea: true,
                            }
                        }]
                    },
                }

            },
            tempoAtendimentoMedio: {
                chatData: {
                    labels: ['segunda', 'terça', 'quarta', 'quinta', 'sexta'],
                    datasets: [
                        {
                            label: 'Atendidos',
                            fontSize: 12,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                            ],
                            borderColor: [
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                            ]
                        }, {
                            label: 'Cancelados',
                            fontSize: 12,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                            ],
                            borderColor: [
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1]
                            ]
                        }
                    ],

                },
                options: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    scales: {
                        yAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                drawOnChartArea: true,
                            }
                        }]
                    },
                }

            },
            atendimentoTempoMedio: {
                chatData: {
                    labels: [
                        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
                        '11', '12', '13', '14', '15', '16', '17', '18', '19',
                        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
                        '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
                        '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
                        '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
                        '60'
                    ],
                    datasets: [
                        {
                            label: 'Atendidos',
                            fontSize: 12,
                            data: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                            ],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],

                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],

                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],

                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],

                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],

                            ],
                            borderColor: [
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],

                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],

                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],

                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],

                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                            ]
                        }, {
                            label: 'Espera',
                            fontSize: 12,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],

                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],

                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],

                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],

                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                            ],
                            borderColor: [
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],

                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],

                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],

                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],

                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],

                            ]
                        }, {
                            label: 'permanência',
                            fontSize: 12,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],

                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],

                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],

                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],

                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                                coresGraficos.preenchimento[2],
                            ],
                            borderColor: [
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],

                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],

                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],

                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],

                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],
                                coresGraficos.linha[2],

                            ]
                        }
                    ],

                },
                options: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    scales: {
                        yAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                drawOnChartArea: true,
                            }
                        }]
                    },
                }

            },
        }
    }

    formataData(state, data) {
        const dd = data.split("-");
        const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
        //const dataFormatada = format(data, 'dd/MM/YYY');
        this.setState({ [state]: dataPronta });
        //console.log(dd);
    }

    formataDataPeriodo(data) {
        const dd = data.split("/");
        const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
        return dataPronta;
    }

    formataDataFiltro() {
        const dataHoje = new Date();
        const data = format(dataHoje, 'YYY-MM-dd');
        return data;
    }

    async componentDidMount() {
        this.filtraDia();
    }

    filtraDia = tipo => {
        const dataFim = this.formataDataFiltro();
        this.enviaFiltragem(dataFim, dataFim);
        this.formataData('dataInicioFormatada', dataFim);
        this.formataData('dataFimFormatada', dataFim);
    }

    filtraSemana = async tipo => {
        const dataHoje = new Date();
        const dataFim = format(dataHoje, 'YYY-MM-dd');
        const dataConvertida = addDays(dataHoje, -6);
        const dataInicio = format(dataConvertida, 'YYY-MM-dd');
        this.enviaFiltragem(dataInicio, dataFim);
        this.formataData('dataInicioFormatada', dataInicio);
        this.formataData('dataFimFormatada', dataFim);
        //console.log(dataInicio);
    }

    filtraMes = async (mes) => {
        const dataHoje = new Date();
        const dataFim = format(dataHoje, 'YYY-MM-dd');
        const dataConvertida = addDays(dataHoje, -30);
        const dataInicio = format(dataConvertida, 'YYY-MM-dd');
        this.enviaFiltragem(dataInicio, dataFim);
        this.formataData('dataInicioFormatada', dataInicio);
        this.formataData('dataFimFormatada', dataFim);
    }

    filtraPeriodo = (value, dateString) => {
        const dataHoje = new Date();
        const data = format(dataHoje, 'dd/MM/YYY');
        const inicio = dateString[0] ? dateString[0] : data;
        const fim = dateString[1] ? dateString[1] : data;
        const dataInicio = this.formataDataPeriodo(inicio);
        const dataFim = this.formataDataPeriodo(fim);
        //console.log("onchange", inicio, fim);

        this.enviaFiltragem(dataInicio, dataFim);
        this.setState({ dataInicioFormatada: inicio });
        this.setState({ dataFimFormatada: fim });
    }

    converteTempo(s) {
        parseInt(s);

        function duas_casas(numero) {
            if (numero <= 9) {
                numero = "0" + numero;
            }
            return numero;
        }

        const hora = duas_casas(Math.trunc(s / 3600));
        const minuto = duas_casas(Math.trunc((s % 3600) / 60));
        const segundo = duas_casas((s % 3600) % 60);

        const formatado = hora + ":" + minuto + ":" + segundo;

        return (formatado);
    }

    enviaFiltragem = async (dataInicio, dataFim) => {

        const ip = localStorage.getItem('MG_IP');

        const dados = await api.get(`/atendimentos/atendimentosPorHora/${dataInicio}/${dataFim}`);
        const info = dados.data;
        //console.log(info)
        const semanas = await api.get(`/atendimentos/atendimentosPorSemana/${dataInicio}/${dataFim}`);
        const semana = semanas.data;
        //console.log(semana)
        const tempos = await api.get(`/atendimentos/atendimentosTempoMedio/${dataInicio}/${dataFim}`);
        const tempo = tempos.data;

        //const atendentes = await api.get(`/atendimentos/atendimentosPorAtendente/${dataInicio}/${dataFim}/${ip}`);
        //const atendente = atendentes.data;

        //atendimentos por hora
        //console.log(atendente);
        //console.log(info[0].total,info[1].total,info[2].total);        
        const novoAtendimentoPorHora0 = Object.assign({}, this.state.atendimentoPorHora);
        //const novoAtendimentoPorHora2 = Object.assign({}, this.state.atendimentoPorHora);
        const totalSenhasHora = Object.assign({}, this.state.totalSenhasHora);
        const somaCanceladosNaoCompareceu = parseInt(info[3].total) + parseInt(info[4].total);
        totalSenhasHora.dados = [info[2].total, info[3].total, info[0].total, info[1].total, somaCanceladosNaoCompareceu, info[5].total];

        novoAtendimentoPorHora0.chatData.datasets[0].data = [info[2].oito, info[2].nove, info[2].dez, info[2].onze, info[2].doze, info[2].treze, info[2].quatorze, info[2].quinze, info[2].dezeseis, info[2].dezesete];
        //novoAtendimentoPorHora2.chatData.datasets[1].data = [info[3].oito, info[3].nove, info[3].dez, info[3].onze, info[3].doze, info[3].treze, info[3].quatorze, info[3].quinze, info[3].dezeseis, info[3].dezesete];
        this.setState({ novoAtendimentoPorHora0, totalSenhasHora });
        //console.log(this.state.totalSenhasHora.dados);


        // atendimento por clientes
        const dadosAtCliente = await api.get(`/projectus/atendimentosPessoa/${dataInicio}/${dataFim}`);
        const infoAtCliente = dadosAtCliente.data;
        const novoAtendimentoCliente0 = Object.assign({}, this.state.atendimentoCliente);

        //console.log('dados cliente', infoAtCliente);
        let dataAtClientes=[];
        let labelAtClientes=[];
        let borderAtClientes=[];
        let backgroundColorAtClientes=[];
        infoAtCliente.map((dado) => {
            dataAtClientes.push(dado.count);
            labelAtClientes.push(dado.nome);
            borderAtClientes.push(coresGraficos.linha[2]);
            backgroundColorAtClientes.push(coresGraficos.preenchimento[2]);
        });

        novoAtendimentoCliente0.chatData.datasets[0].data = dataAtClientes;
        novoAtendimentoCliente0.chatData.labels = labelAtClientes;
        novoAtendimentoCliente0.chatData.datasets[0].borderColor = borderAtClientes;
        novoAtendimentoCliente0.chatData.datasets[0].backgroundColor = backgroundColorAtClientes;
        this.setState({ novoAtendimentoCliente0 });



        //atendimentos por semana
        //console.log("período:", dataInicio,dataFim);
        //console.log(info[0].total,info[1].total,info[2].total);        
        const novoAtendimentoPorSemana0 = Object.assign({}, this.state.atendimentoPorSemana);
        //const novoAtendimentoPorSemana2 = Object.assign({}, this.state.atendimentoPorSemana);

        const totalSenhas = Object.assign({}, this.state.totalSenhas);
        const somaSenhaSemana = parseInt(semana[3].total) + parseInt(semana[4].total);
        totalSenhas.dados = [semana[2].total, semana[3].total, semana[0].total, semana[1].total, somaSenhaSemana, semana[5].total];
        novoAtendimentoPorSemana0.chatData.datasets[0].data = [semana[2].segunda, semana[2].terca, semana[2].quarta, semana[2].quinta, semana[2].sexta];
        //novoAtendimentoPorSemana2.chatData.datasets[1].data = [semana[2].segunda, semana[2].terca, semana[2].quarta, semana[2].quinta, semana[2].sexta];
        this.setState({ novoAtendimentoPorSemana0, totalSenhas });
        //console.log(this.state.totalSenhas.dados);

        //por tempo médio
        const novoAtendimentoTempoMedio0 = Object.assign({}, this.state.atendimentoTempoMedio);
        const novoAtendimentoTempoMedio1 = Object.assign({}, this.state.atendimentoTempoMedio);
        const novoAtendimentoTempoMedio2 = Object.assign({}, this.state.atendimentoTempoMedio);

        const totalTempoMedio = Object.assign({}, this.state.totalTempoMedio);
        totalTempoMedio.dados = [this.converteTempo(tempo[0].media),
        this.converteTempo(tempo[0].maximo),
        this.converteTempo(tempo[1].media),
        this.converteTempo(tempo[1].maximo),
        this.converteTempo(tempo[2].media),
        this.converteTempo(tempo[2].maximo)];

        //console.log(totalTempoMedio);
        this.setState({ totalTempoMedio });
        novoAtendimentoTempoMedio0.chatData.datasets[0].data = Object.values(tempo[0]).slice(0, 61);
        novoAtendimentoTempoMedio1.chatData.datasets[1].data = Object.values(tempo[1]).slice(0, 61);
        novoAtendimentoTempoMedio2.chatData.datasets[2].data = Object.values(tempo[2]).slice(0, 61);

        this.setState({ novoAtendimentoTempoMedio0, novoAtendimentoTempoMedio1, novoAtendimentoTempoMedio2 });
        //console.log('tempo', tempo);
    }

    render() {
        const { RangePicker } = DatePicker;
        const { Title, Paragraph } = Typography;
        const { totalSenhas, totalSenhasHora } = this.state;
        const { totalTempoMedio } = this.state;
        return (
            <div>
                <Row gutter={24} type="flex" justify="space-between">
                    <Col lg={11}>
                        <Title style={{ fontWeight: 'bold' }} level={2}>Atendimentos</Title>
                    </Col>
                    <Col md={12} lg={12} xl={12} >
                        <Title style={{ textAlign: 'right' }} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
                    </Col>
                </Row>
                <Row gutter={24} type="flex" justify="end">
                    <Col md={12} lg={12} xl={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
                        <RangePicker
                            placeholder={['Período...']}
                            //onOk={this.onOk}
                            onChange={this.filtraPeriodo}
                            //defaultValue={[moment(dataHoje, 'DD/MM/YYYY'), moment(dataHoje, 'DD/MM/YYYY')]}
                            format={'DD/MM/YYYY'}
                        />{'  '}
                        <Radio.Group value={14} >
                            <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
                            <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
                            <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginBottom: '15px' }}>
                    <Col style={{ marginBottom: '20px' }} md={24} lg={12} xl={12}>
                        <Card size="small" title={<div><b>REALIZADOS POR HORA</b></div>}>
                            <Col md={24} lg={24} xl={24}>
                                <Barra width={100} height={60} periodo={this.state.atendimentoPorHora.chatData} options={this.state.options} />
                            </Col>
                            <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhasHora.dados[0] ? totalSenhasHora.dados[0] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Atendimentos realizados</Paragraph>
                                </Col>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[5], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhasHora.dados[4] ? totalSenhasHora.dados[4] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Senhas canceladas</Paragraph>
                                </Col>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhasHora.dados[2] ? totalSenhasHora.dados[2] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Aguardando atendimento</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[3], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhasHora.dados[3] ? totalSenhasHora.dados[3] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Sendo atendido</Paragraph>
                                </Col>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[2], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhasHora.dados[5] ? totalSenhasHora.dados[5] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Senhas geradas</Paragraph>
                                </Col>
                            </Col>
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: '20px' }} md={24} lg={12} xl={12}>
                        <Card size="small" title={<div><b>REALIZADOS POR SEMANA</b></div>}>
                            <Col md={24} lg={24} xl={24}>
                                <Barra width={100} height={60} periodo={this.state.atendimentoPorSemana.chatData} options={this.state.options} />
                            </Col>
                            <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhas.dados[0] ? totalSenhas.dados[0] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Atendimentos realizados</Paragraph>
                                </Col>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[5], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhas.dados[4] ? totalSenhas.dados[4] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Senhas canceladas</Paragraph>
                                </Col>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhas.dados[2] ? totalSenhas.dados[2] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Aguardando atendimento</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[3], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhas.dados[3] ? totalSenhas.dados[3] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Sendo atendido</Paragraph>
                                </Col>
                                <Col md={5} lg={5} xl={5}>
                                    <Title style={{ color: coresGraficos.linha[2], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}>{totalSenhas.dados[5] ? totalSenhas.dados[5] : 0}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Senhas geradas</Paragraph>
                                </Col>

                            </Col>
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: '20px' }} md={24} lg={24} xl={24}>
                        <Card size="small" title={<div><b>TEMPO MÉDIO</b></div>}>
                            <Col md={24} lg={24} xl={24}>
                                <Barra width={100} height={parseInt(localStorage.getItem('heightGraphic'))} periodo={this.state.atendimentoTempoMedio.chatData} options={this.state.options} />
                            </Col>
                            <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{totalTempoMedio.dados[0]}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Tempo médio</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{totalTempoMedio.dados[1]}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Tempo máximo</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{totalTempoMedio.dados[2]}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Tempo médio</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{totalTempoMedio.dados[3]}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Tempo máximo</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[2], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{totalTempoMedio.dados[4]}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Tempo médio</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[2], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{totalTempoMedio.dados[5]}</Title>
                                    <Paragraph style={{ marginBottom: '10px', fontSize: '14px' }}>Tempo máximo</Paragraph>
                                </Col>
                            </Col>
                        </Card>
                    </Col>
                    <Col style={{ marginBottom: '20px' }} md={24} lg={24} xl={24}>
                        <Card size="small" title={<div><b>ATENDIMENTO POR CLIENTES</b></div>}>
                            <Col md={24} lg={24} xl={24}>
                                <Barra width={100} height={parseInt(localStorage.getItem('heightGraphic'))} periodo={this.state.atendimentoCliente.chatData} options={this.state.options} />
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </div>
        );
    }
}
