import axios from 'axios';

let URL;
if(process.env.NODE_ENV === 'development'){
    URL = 'http://192.168.1.166:3001/api'; 
    //URL = 'http://localhost:3001/api'; 
}else{
    URL = 'https://macgestao.mactus.online/api';
    //URL = 'http://192.168.1.166:3001/api'; 
    
}

 const api = axios.create({    
    baseURL:URL
    
})

export default api;
