import React, { useState, useEffect } from 'react';
import JwtDecode from 'jwt-decode';
import api from '../../services/apiLogin';
import { Form, Input } from '@rocketseat/unform';
import FormData from 'form-data'
import '../../css/global.css';
//import apiPhp from '../../services/apiPhp';
//import api from '../../services/api';
import * as Yup from 'yup';
import history from '../../services/history';
import { saveToken, getToken } from '../../utils/LoginManager';
import Cookies from 'universal-cookie';

const schema = Yup.object().shape({
  email: Yup.string().required('Insira um login válido'),
  senha: Yup.string().required('Digite sua senha'),
});

const Login = (props) => {
  const [mensagem, setMensagem] = useState("");

  const cookies = new Cookies();

  // const tk = cookies.get('token');

  // axios.post('/', tk);
  // console.log(tk);
  useEffect(() => {
    const token = getToken();       
    //const token2 = cookies.get('mgtoken');
    //console.log("local",token,"painel",token2);
    if (token) {
      const tokenDados = JwtDecode(getToken());
      //console.log(tokenDados);
      //const configuracao = JSON.parse(tokenDados.dadosAdicionais);
      //console.log(tokenDados);
      //const token =  mgtoken == dados.token; 
      //console.log(configuracao);
      //localStorage.setItem('MG_IP', configuracao[0].ip);
      localStorage.setItem('MG_IP', '192.168.1.166');
      localStorage.setItem('MG_CLIENTE', tokenDados.name);
      localStorage.setItem('MG_ID', tokenDados.id);
 
      //   console.log("token igual");      
      history.push('/dashboard');      

    }
    // if(props.match.params.token){
    //   console.log(props.match.params.token)
    //   saveToken(props.match.params.token);
    //   history.push('/dashboard');
    // }else{
    //   console.log('falhou')
    // }
  }, [cookies]);  

  function handleSubmit({ email, senha }) {
    verificaLogin(email, senha);
  }

  async function verificaLogin(email, senha) {
    const bodyFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    bodyFormData.append('login', email)
    bodyFormData.append('senha', senha)

    //console.log(email,senha)  
    //const usuario = await api.post('/api/APILogin.php', bodyFormData, config);  
    const usuario = await api.post('/api/Login.htm', bodyFormData, config);  

    const dados = usuario.data;
    console.log('****dados ',dados);
    if (dados.isErro == true) {
      setMensagem(dados.mensagem);
    } else {
      
      //console.log(dados);
      //configurações da serventia
      //console.log(dados.configuracoes);
      saveToken(dados.token);
      const token = getToken();

      //const configura = JwtDecode(dados.token);
      //const configuracao = JSON.parse(configura.dadosAdicionais);
      //const token =  mgtoken == dados.token;
      //localStorage.setItem('MG_IP', configuracao[0].ip);
      //localStorage.setItem('MG_IP', '');
      localStorage.setItem('MG_IP', '192.168.1.166');
      localStorage.setItem('MG_CLIENTE', dados.nomeUsuario);
      
      //console.log(token);      

      if (token) {
        //console.log("token igual");
        setMensagem(dados.mensagem);
        history.push('/dashboard');       
      }
    }

  }

  return (
    <Form schema={schema} className="form-login" onSubmit={handleSubmit}>
      <img className="login-logo" src="../../mactus-branca.png" alt="" />
      <div className="form-login-mensagem">{mensagem}</div>
      <Input className="input-login" maxLength={50} name="email" type="text" placeholder="Login" />
      <Input className="input-login" maxLength={40} name="senha" type="password" placeholder="Senha" />
      <button className="button-login" type="submit">Acessar</button>
    </Form>
  );
}

export default Login;

