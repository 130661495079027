import React, { Component } from 'react';
import { Row, Col } from 'antd';


export default class Relatorio extends Component {
  state = {
    name: '',    
  }

  async componentDidMount(){

  }


  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col lg={24}>
            <h2>Relatórios</h2>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col  md={24} lg={12} xl={8}>        
          
          </Col>  
          <Col  md={24} lg={12} xl={8}>        
          
          </Col>     
          <Col  md={24} lg={12} xl={8}>        
         
          </Col>       
        </Row>
        <Row gutter={24}>
          <Col lg={24}>
            <br></br>
          </Col>
        </Row>
        
        <Row gutter={24}>
          <Col md={24}>        
          
          </Col>                
        </Row>
      </div>
    );
  }
}
