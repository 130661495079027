import React from 'react';
import { Bar } from 'react-chartjs-2';

function Barra(props) {
    return (
        <Bar        
            data={props.periodo}
            width={props.width}
            height={props.height}
            options={props.options}
        />
    );
}

export default Barra;
