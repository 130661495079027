import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { Table, Row, Col, Radio, Typography, DatePicker, Input } from 'antd';
import '../../../css/global.css';

import api from '../../../services/api';


export default class Atendimento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicioFormatada: '',
      dataFimFormatada: '',
      dados: [
        { atendente: 'Marcio', atendimento: 20, tempo_medio: 200, tempo_maximo: 140 },
        { atendente: 'hugo', atendimento: 210, tempo_medio: 220, tempo_maximo: 40 },
      ],
    }
  }

  formataData(state, data) {
    const dd = data.split("-");
    const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
    //const dataFormatada = format(data, 'dd/MM/YYY');
    this.setState({ [state]: dataPronta });
    //console.log(dd);
  }

  formataDataPeriodo(data) {
    const dd = data.split("/");
    const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
    return dataPronta;
  }

  formataDataFiltro() {
    const dataHoje = new Date();
    const data = format(dataHoje, 'YYY-MM-dd');
    return data;
  }

  async componentDidMount() {
    this.filtraDia();
  }

  filtraDia = e => {
    const dataFim = this.formataDataFiltro();
    this.enviaFiltragem(dataFim, dataFim);
    this.formataData('dataInicioFormatada', dataFim);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraSemana = async e => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -6);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
    console.log(dataInicio);
  }

  filtraMes = async mes => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -30);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraPeriodo = (value, dateString) => {
    const dataHoje = new Date();
    const data = format(dataHoje, 'dd/MM/YYY');
    const inicio = dateString[0] ? dateString[0] : data;
    const fim = dateString[1] ? dateString[1] : data;
    const dataInicio = this.formataDataPeriodo(inicio);
    const dataFim = this.formataDataPeriodo(fim);

    //console.log("onchange", inicio, fim);

    this.enviaFiltragem(dataInicio, dataFim);
    this.setState({ dataInicioFormatada: inicio });
    this.setState({ dataFimFormatada: fim });
  }

  converteTempo(s) {

    function duas_casas(numero) {
      if (numero <= 9) {
        numero = "0" + numero;
      }
      return numero;
    }

    const hora = duas_casas(Math.round(s / 3600));
    const minuto = duas_casas(Math.floor((s % 3600) / 60));
    const segundo = duas_casas((s % 3600) % 60);

    const formatado = hora + ":" + minuto + ":" + segundo;

    return (formatado);
  }

  proximaPagina = async (pg) =>{
    const atendimentos = await api.get(`/atendimentos/relatorioAtendimentosPorAtendente/${pg}`);
    
  }

  enviaFiltragem = async (atendente, dataInicio, dataFim) => {

    //atendimentos gerais
    const atendimentos = await api.get(`/atendimentos/relatorioAtendimentosPorAtendente/${atendente}/${dataInicio}/${dataFim}`);
    const dadosAtendimento = atendimentos.data;
    this.setState({ dados: dadosAtendimento });
    //console.log(dadosAtendimento[0].tempo_maximo);
  }

  render() {
    const { Column } = Table;
    const { RangePicker } = DatePicker;
    const { Title } = Typography;
    const { Search } = Input;

    return (
      <>
        <Row gutter={24} type="flex" justify="space-between">
          <Col lg={11}>
            <Title style={{ fontWeight: 'bold' }} level={2}>Atendimentos</Title>
          </Col>
          <Col md={12} lg={12} xl={12} >
            <Title style={{ textAlign: 'right' }} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="end">
          <Col md={24} lg={20} xl={20} style={{ marginBottom: '10px', textAlign: 'right' }}>
            <Search
              placeholder="Atendente"
              onSearch={value => console.log(value)}
              style={{ width: 250 }}
            />{'  '}
            <RangePicker
              placeholder={['Período...']}
              //onOk={this.onOk}
              onChange={this.filtraPeriodo}
              //defaultValue={[moment(dataHoje, 'DD/MM/YYYY'), moment(dataHoje, 'DD/MM/YYYY')]}
              format={'DD/MM/YYYY'}
            />{'  '}
            <Radio.Group value={14} >
              <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
              <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
              <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Table dataSource={this.state.dados}>
          <Column title="ATENDENTE" dataIndex="atendente" key="atendente" />
          <Column title="ATENDIMENTO" dataIndex="atendimento" key="atendimento" />
          <Column title="TEMPO MÉDIO DE ATENDIMENTO" dataIndex="tempo_medio" key="tempo_medio"
            render={tempo_medio => (
              this.converteTempo(tempo_medio)
            )
            } />
          <Column title="TEMPO MÁXIMO DE ATENDIMENTO" dataIndex="tempo_maximo" key="tempo_maximo"
            render={tempo_maximo => (
              this.converteTempo(tempo_maximo)
            )
            }
          />
        </Table>
      </>
    );
  }
}
