import React from 'react';
import { Line } from 'react-chartjs-2';


function Linha(props) {
    return (
        <Line
            data={props.periodo}
            width={props.width}
            height={props.height}
            options={{
                // title:{
                //     display:true,
                //     fontSize:16,
                //     fontFamily:'Helvetica, Arial, sans-serif',
                //     text:"Recepção",
                //     position:'top',
                //     stacked:false,
                // },
                scales: {
                    yAxes: [{
                        type: 'linear',                        
                        ticks: {
                            beginAtZero: true,                            
                        
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            
                        }
                    }]
                },
                maintainAspectRatio: true,
                
            }}
        />

    );
}

export default Linha;
