import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { Row, Col, Radio, Card, Icon, DatePicker, Typography, Statistic } from 'antd';
//import moment from 'moment';
import 'moment/locale/pt-br';

//import { getToken } from '../../utils/LoginManager';
//import Cookies from 'universal-cookie';

import '../../css/global.css';
//import Pizza from '../../components/graficos/Pizza';
import Barra from '../../components/graficos/Barra';
import coresGraficos from '../../Config/cores';

//import { Link } from 'react-router-dom';
import api from '../../services/api';
import { If } from '../../components/if';


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    //const cookies = new Cookies();
    this.state = {
      dataInicioFormatada: '',
      dataFimFormatada: '',
      //checked: true,      
      atendimento: {
        chatData: {
          labels: ['Atendidos', 'Cancelados', 'Aguardando', 'Sendo Atendido'],
          position: "bottom",
          datasets: [
            {
              label: 'Atendimentos',
              borderWidth: 1,
              data: [0, 0, 0, 0],
              backgroundColor: [
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[5],
                coresGraficos.preenchimento[2],
                coresGraficos.preenchimento[3],
              ],
              borderColor: [
                coresGraficos.linha[0],
                coresGraficos.linha[5],
                coresGraficos.linha[2],
                coresGraficos.linha[3],

              ]
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      },
    }
  }

  formataData(state, data) {
    const dd = data.split("-");
    const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
    //const dataFormatada = format(data, 'dd/MM/YYY');
    this.setState({ [state]: dataPronta });
    //console.log(dd);
  }

  formataDataPeriodo(data) {
    const dd = data.split("/");
    const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
    return dataPronta;
  }

  formataDataFiltro() {
    const dataHoje = new Date();
    const data = format(dataHoje, 'YYY-MM-dd');
    return data;
  }

  async componentDidMount() {
    this.filtraDia();
  }

  filtraDia = e => {
    const dataFim = this.formataDataFiltro();
    this.enviaFiltragem(dataFim, dataFim);
    this.formataData('dataInicioFormatada', dataFim);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraSemana = async e => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -6);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
    //console.log(dataInicio);
  }

  filtraMes = async mes => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -30);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraPeriodo = (value, dateString) => {
    const dataHoje = new Date();
    const data = format(dataHoje, 'dd/MM/YYY');
    const inicio = dateString[0] ? dateString[0] : data;
    const fim = dateString[1] ? dateString[1] : data;
    const dataInicio = this.formataDataPeriodo(inicio);
    const dataFim = this.formataDataPeriodo(fim);

    //console.log("onchange", inicio, fim);

    this.enviaFiltragem(dataInicio, dataFim);
    this.setState({ dataInicioFormatada: inicio });
    this.setState({ dataFimFormatada: fim });
  }

  somaTotal = (dados) => {
    //let teste = [2,3,4,5];  
    const total = dados.reduce((dado, valor) => {
      return dado + valor;
    });
    return total;
  }

  enviaFiltragem = async (dataInicio, dataFim) => {
    // const atendimentos = await api.get(`/atendimentos/mediaAtendimentos/${dataInicio}/${dataFim}`);
    // const atendimento = atendimentos.data;

    // const atendimentoFinal = atendimentos.data[0].atendimento === null ? 0 : atendimentos.data[0].atendimento;
    // const novoEstadoAtendimento = Object.assign({}, this.state);
    // novoEstadoAtendimento.atendimento.chatData.datasets[0].data = [atendimentoFinal,atendimento[0].espera];
    // this.setState({ novoEstadoAtendimento });    
    //const ip = localStorage.getItem('MG_IP');    
    //atendimentos gerais

    const atendimentosGerais = await api.get(`/atendimentos/atendimentosFinalizados/${dataInicio}/${dataFim}`);
    const atendimentoGeral = atendimentosGerais.data;
    //console.log(atendimentoGeral)

    const atendimentoGeralFinal = atendimentosGerais.data[0].atendimento === null ? 0 : atendimentosGerais.data[0].atendidos;
    const novoEstadoAtendimentoGeral = Object.assign({}, this.state);
    novoEstadoAtendimentoGeral.atendimento.chatData.datasets[0].data = [atendimentoGeralFinal, atendimentoGeral[0].cancelados, atendimentoGeral[0].aguardando, atendimentoGeral[0].atendendo];
    this.setState({ novoEstadoAtendimentoGeral });

  }



  render() {
    const { RangePicker } = DatePicker;
    const { Title } = Typography;
    return (
      <div>
        <Row gutter={24} type="flex" justify="space-between">
          <Col md={12} lg={12} xl={12}>
            <Title style={{ fontWeight: 'bold' }} level={2}>Dashboard </Title>
          </Col>

          <Col md={12} lg={12} xl={12} >
            <Title style={{ textAlign: 'right' }} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="end">
          <Col md={12} lg={12} xl={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
            <RangePicker
              placeholder={['Período...']}
              //onOk={this.onOk}
              onChange={this.filtraPeriodo}
              //defaultValue={[moment(dataHoje, 'DD/MM/YYYY'), moment(dataHoje, 'DD/MM/YYYY')]}
              format={'DD/MM/YYYY'}
            />{'  '}
            <Radio.Group value={14} >
              <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
              <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
              <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Row gutter={24}>
          <If teste={true}>
            <Col md={24} lg={24} xl={24}>
              <Card size="small" style={{ borderColor: coresGraficos.linha[0], borderWidth: '1px', marginBottom: '15px', background: coresGraficos.linha[0] }}>
                <h4 style={{ color: '#fff' }}>CLIENTES ATENDIDOS</h4>
                <Statistic
                  value={this.state.atendimento.chatData.datasets[0].data[0]}
                  valueStyle={{ fontSize: '28px', color: '#fff' }}
                  prefix={<Icon type="team" />} />
              </Card>
            </Col>
          </If>
        </Row>

        <Row gutter={24}>
          <If teste={true}>
            <Col md={24} lg={24} xl={24}>
              <Card size="small" title={<div><b>ATENDIMENTO</b></div>}>
                <Barra width={80} height={34} periodo={this.state.atendimento.chatData} options={this.state.atendimento.options} />
              </Card>
            </Col>
          </If>
        </Row>
      </div>
    );
  }
}
