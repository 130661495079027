import React from 'react';
import {Switch,Route,Redirect } from 'react-router-dom';
import {isAutenticated} from '../auth';
import Login from '../pages/Login';

import Dashboard from '../pages/Dashboard';
import Atendimento from '../pages/Graficos/Atendimento';
import Atendente from '../pages/Graficos/Atendente';
import Certidao from '../pages/Graficos/Certidao';
import Protocolo from '../pages/Graficos/Protocolo';
import Relatorio from '../pages/Relatorios';
import RelatorioAtendimento from '../pages/Relatorios/Atendimento';
//import If from '../components/if';

const PrivateRouter = ({component:Component, ...rest}) => (
    <Route {...rest} render={props => (
        isAutenticated() ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{pathname: '/', state: {from:props.location}}} />
        )
    )} />
);

export default function Routes(){  
    return(
        
        <Switch>            
            <Route path="/" exact component={Login}/>            
            <PrivateRouter path="/dashboard" exact component={Dashboard}/>
            <PrivateRouter path="/atendimento" component={Atendimento} />
            <PrivateRouter path="/atendentes" component={Atendente}/>
            <PrivateRouter path="/protocolo" component={Protocolo} />
            <PrivateRouter path="/certidao" component={Certidao}/>
            <PrivateRouter path="/relatorio" component={Relatorio}/>
            <PrivateRouter path="/relatorioAtendimento" component={RelatorioAtendimento}/>            
        </Switch>                     
    )
}