import React, { useState, useEffect } from 'react';

import 'antd/dist/antd.css';
import './css/global.css';
import {
  isMobile
} from "react-device-detect";
import { removeToken, getToken } from './utils/LoginManager';
import { BrowserRouter, Link, Router } from 'react-router-dom';
import Routes from './routes';
import { Layout, Menu, Icon, Button } from 'antd';
import history from './services/history';
import { If } from '../src/components/if';
import Cookies from 'universal-cookie';

//import {isAutenticated } from './auth/';
//import Title from 'antd/lib/skeleton/Title';
//import { getUsuario } from './utils/LoginManager';

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const cookies = new Cookies();

export default function App() {
  const [usuario, setUsuario] = useState();
  const [collapsed, setColapsed] = useState(false);
  const [logo, setLogo] = useState('../../mactus-branca.png');
  const [mobile, setMobile] = useState(false);
  //const [demonstrativo, setDemonstrativo] = useState(false);
  //const tokem = cookies.set('token',dado.token);
  //console.log(tokem);

  function verificaMobile() {
    if (isMobile) {
      return true
    }
    return false
  }

  function toggle() {
    setUsuario(localStorage.getItem('MG_CLIENTE'));
    setColapsed(!collapsed);
    if (collapsed === false) {
      setLogo('../../favicon.png');
    } else {
      setLogo('../../mactus-branca.png');
    }
  }

  useEffect(() => {
    //const logado = getToken();
    //console.log("pegou",logado);
    //ambienteDeTeste();    
    setUsuario(localStorage.getItem('MG_CLIENTE'));
    setMobile(verificaMobile());
    //ambienteDemonstrativo();    
    console.log('passou');
    if (mobile === true) {
      setColapsed(true);
      setLogo('../../favicon.png');
      localStorage.setItem('heightGraphic', 90);
    } else {
      localStorage.setItem('heightGraphic', 40);
    }

  }, [mobile]);

  function logout() {
    //const logado = getToken();
    //console.log(logado);
    //if (logado) {
    cookies.remove('mgtoken', { domain: 'mactus.online' });
    removeToken();
    localStorage.setItem('MG_CLIENTE', "");
    localStorage.setItem('MG_IP', "");
    localStorage.setItem('MG_ID', "");
    setUsuario("");
    history.push("./");
    //}
  }

  function ambienteDemonstrativo() {
    const ip = localStorage.getItem('MG_IP');
    const id = localStorage.getItem('MG_ID');
    if (ip === '192.168.3.24' && id !== '14') {
      return "AMBIENTE DE DEMONSTRAÇÃO";
    }
  }

  return (
    <BrowserRouter>
      <Router history={history}>
        <Layout lg={24} >
          <If teste={getToken()}>
            <Sider style={{ weight: '100px' }} trigger={null} collapsible collapsed={collapsed}>
              <Link to="/dashboard"><img className="logo" src={logo} alt="" /></Link>
              {/* <h4 style={{ fontWeight: 'bold', color: '#fff', margin: '10px' }} level={2}>{usuario}</h4> */}
              <If teste={true}>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                  <SubMenu
                    key="1"
                    title={
                      <span>
                        <Icon style={{ fontSize: 22 }} type="pie-chart" />
                        <span>Gráficos</span>
                      </span>
                    }
                  >
                    <Menu.Item key="2"><Link to="/atendimento"><Icon type="team" />Atendimentos</Link></Menu.Item>
                    <Menu.Item key="3"><Link to="/atendentes"><Icon type="team" />Atendentes</Link></Menu.Item>
                  </SubMenu>
                </Menu>
              </If>
              {/* <Menu theme="dark" defaultSelectedKeys={['2']} mode="inline">
            <SubMenu
              key="2"
              title={
                <span>
                  <Icon style={{ fontSize: 22 }} type="table" />
                  <span>Relatórios</span>
                </span>
              }
            >
              <Menu.Item key="1"><Link to="/RelatorioAtendimento"><Icon type="team" />Atendimentos</Link></Menu.Item>
              <Menu.Item key="2"><Link to="/RelatorioAtendimentoAtendente"><Icon type="team" />Atendentes</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/"><Icon type="audit" />Certidões</Link></Menu.Item>
              <Menu.Item key="4"><Link to="/"><Icon type="file-done" />Protocolos</Link></Menu.Item>
              <Menu.Item key="5"><Link to="/"><Icon type="file-text" />Exames Cálculo</Link></Menu.Item>
            </SubMenu>
          </Menu> */}
            </Sider>
          </If>
          <Layout>
            <If teste={true}>
              <Header style={{ background: '#fff', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >

                <Icon onClick={toggle}
                  className="trigger"
                  style={{ marginLeft: 15, fontSize: 20 }}
                  type={collapsed ? 'menu-unfold' : 'menu-fold'}
                />

                <If teste={ambienteDemonstrativo()}>
                  <div className="ambiente-demonstrativo">
                    {ambienteDemonstrativo()}
                  </div>
                </If>

                <Button onClick={logout}>
                  <Icon style={{ fontSize: '22px', marginRight: '5px' }} type="logout" />
                  Sair
              </Button>
              </Header>
            </If>
            <div style={{
              margin: '15px', background: '#fff', boxShadow: '0 0 4px #e6e6e6', padding: '20px',
              borderRadius: '4px', boxSizing: 'border-box', minHeight: '80vh'
            }}>
              <Routes />
            </div>
            <Footer style={{ textAlign: 'center' }}>MacGestão - Desenvolvido por Mactus Soluções em T.I.</Footer>
          </Layout>
        </Layout>
      </Router>
    </BrowserRouter>
  );
}
