import axios from 'axios';

let URL;
console.log("ambiente ",process.env.NODE_ENV);
if(process.env.NODE_ENV === 'development'){
    //URL = 'http://192.168.2.51:8083';
    URL = 'http://192.168.1.166:8080/Projectus';
}else{
    //URL = 'https://macfinanceiro.mactus.com.br';
    URL = 'https://projectus.mactus.com.br/Projectus';
    //URL = 'http://192.168.1.166:8080/Projectus';
}

const apiLogin = axios.create({    
    baseURL:URL    
});

export default apiLogin;