import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { Row, Col, Radio, Card, DatePicker, Typography } from 'antd';
//import moment from 'moment';
import 'moment/locale/pt-br';

import '../../../css/global.css';
import Linha from '../../../components/graficos/Linha';
import coresGraficos from '../../../Config/cores';

//import { Link } from 'react-router-dom';
import api from '../../../services/api';
//import { If } from '../../../components/if';


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicioFormatada: '',
      dataFimFormatada: '',
      totalProtocolos: { dados: [0, 0] },
      //checked: true,

      protocolo: {
        chatData: {
          labels: [
            '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
            '11', '12', '13', '14', '15', '16', '17', '18', '19',
            '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
            '30', '31'
          ],
          datasets: [
            {
              label: 'Entrada',
              fontSize: 12,
              borderWidth: 2,
              fill:null,
              lineTension:0,
              data: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
              ],
              backgroundColor: [
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
                coresGraficos.preenchimento[0],
              ],
              borderColor: [
                coresGraficos.linha[0],
              ]
            }, {
              label: 'Registrados',
              fontSize: 12,
              borderWidth: 2,
              lineTension:0,
              fill:null,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                     0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                     0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0],              
              backgroundColor: [
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
                coresGraficos.preenchimento[1],
              ],
              borderColor: [
                coresGraficos.linha[1],

              ]
            }
          ],

        },
        options: {       
          legend: {
            display: false,
            position: "bottom",           
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }

      },

    }
  }


  formataData(state, data) {
    const dd = data.split("-");
    const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
    //const dataFormatada = format(data, 'dd/MM/YYY');
    this.setState({ [state]: dataPronta });
    //console.log(dd);
  }

  formataDataPeriodo(data) {
    const dd = data.split("/");
    const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
    return dataPronta;
  }

  formataDataFiltro() {
    const dataHoje = new Date();
    const data = format(dataHoje, 'YYY-MM-dd');
    return data;
  }

  async componentDidMount() {
    this.filtraDia();
  }

  filtraDia = e => {
    const dataFim = this.formataDataFiltro();
    this.enviaFiltragem(dataFim, dataFim);
    this.formataData('dataInicioFormatada', dataFim);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraSemana = async e => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -6);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
    //console.log(dataInicio);
  }

  filtraMes = async mes => {
    const dataHoje = new Date();
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -30);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');
    this.enviaFiltragem(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
  }

  filtraPeriodo = (value, dateString) => {
    const dataHoje = new Date();
    const data = format(dataHoje, 'dd/MM/YYY');
    const inicio = dateString[0] ? dateString[0] : data;
    const fim = dateString[1] ? dateString[1] : data;
    const dataInicio = this.formataDataPeriodo(inicio);
    const dataFim = this.formataDataPeriodo(fim);

    //console.log("onchange", inicio, fim);

    this.enviaFiltragem(dataInicio, dataFim);
    this.setState({ dataInicioFormatada: inicio });
    this.setState({ dataFimFormatada: fim });
  }

  somaTotal = (dados) => {
    //let teste = [2,3,4,5];  
    const total = dados.reduce((dado, valor) => {
      return dado + valor;
    });
    return total;
  }

  enviaFiltragem = async (dataInicio, dataFim) => {

    const ip = localStorage.getItem('MG_IP');

    //protocolos
    const protocoloPronto = await api.get(`/protocolo/protocoloPorMes/${dataInicio}/${dataFim}/${ip}`);
    const protocolo = protocoloPronto.data;
    //console.log(protocolo[0].total,protocolo[1].total);
    //const protocoloFinal = protocoloPronto.data[0].pronto === null ? 0 : protocoloPronto.data[0].pronto;
    //const totalProtocolo = parseInt(protocolo[0].andamento) + parseInt(protocolo[0].pronto);
    // const novoEstadoProtocolo = Object.assign({}, this.state);
    // novoEstadoProtocolo.protocolo.chatData.datasets[0].data = [ protocolo[0].entrada, protocolo[0].pronto];
    // this.setState({ novoEstadoProtocolo });

       //por tempo médio
       const novoProtocolo0 = Object.assign({}, this.state.protocolo);
       const novoProtocolo1 = Object.assign({}, this.state.protocolo);
       
       const totalProtocolo = Object.assign({}, this.state.totalProtocolos);
       totalProtocolo.dados = [protocolo[0].total,protocolo[1].total];
       this.setState({ totalProtocolos:totalProtocolo }); 
       //console.log(totalTempoMedio);
       //this.setState({ totalTempoMedio });
       novoProtocolo0.chatData.datasets[0].data = Object.values(protocolo[0]).slice(0,31);
       novoProtocolo1.chatData.datasets[1].data = Object.values(protocolo[1]).slice(0,31);
      //  novoAtendimentoTempoMedio2.chatData.datasets[2].data = Object.values(tempo[2]).slice(0,61);

       this.setState({ novoProtocolo0,novoProtocolo1 });

  }

  render() {
    const { RangePicker } = DatePicker;
    const { Title, Paragraph } = Typography;
    return (
      <div>
        <Row gutter={24} type="flex" justify="space-between">
          <Col md={12} lg={12} xl={12}>
            <Title style={{ fontWeight: 'bold' }} level={2}>Protocolos </Title>
          </Col>
          <Col md={12} lg={12} xl={12} >
            <Title style={{ textAlign: 'right' }} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="end">
          <Col md={12} lg={12} xl={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
            <RangePicker
              placeholder={['Período...']}
              //onOk={this.onOk}
              onChange={this.filtraPeriodo}
              //defaultValue={[moment(dataHoje, 'DD/MM/YYYY'), moment(dataHoje, 'DD/MM/YYYY')]}
              format={'DD/MM/YYYY'}
            />{'  '}
            <Radio.Group value={14} >
              <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
              <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
              <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={24}>
            <Card size="small" title={<div><b>MOVIMENTAÇÃO MENSAL</b></div>}>
              <Col md={24}>
                <Linha width={100} height={35} periodo={this.state.protocolo.chatData} />
              </Col>
              <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>
                <Col md={12} lg={12} xl={12}>
                  <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalProtocolos.dados[0]}</Title>
                  <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Total Entrada</Paragraph>
                </Col>
                <Col md={12} lg={12} xl={12}>
                  <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalProtocolos.dados[1]}</Title>
                  <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Total Registrados</Paragraph>
                </Col>
                {/* <Col md={8} lg={8} xl={8}>
                  <Title style={{ color: coresGraficos.linha[2], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalProtocolos.dados[0] - this.state.totalProtocolos.dados[1]}</Title>
                  <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Em Andamento</Paragraph>
                </Col> */}
              </Col>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
